define("discourse/plugins/discourse-reactions/discourse/controllers/user-activity-reactions", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/plugins/discourse-reactions/discourse/models/discourse-reactions-custom-reaction"], function (_exports, _tracking, _controller, _object, _service, _discourseReactionsCustomReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserActivityReactions extends _controller.default {
    static #_ = dt7948.g(this.prototype, "siteSettings", [_service.inject]);
    #siteSettings = (dt7948.i(this, "siteSettings"), void 0);
    static #_2 = dt7948.g(this.prototype, "application", [_controller.inject]);
    #application = (dt7948.i(this, "application"), void 0);
    static #_3 = dt7948.g(this.prototype, "canLoadMore", [_tracking.tracked], function () {
      return true;
    });
    #canLoadMore = (dt7948.i(this, "canLoadMore"), void 0);
    static #_4 = dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    });
    #loading = (dt7948.i(this, "loading"), void 0);
    static #_5 = dt7948.g(this.prototype, "beforeLikeId", [_tracking.tracked], function () {
      return null;
    });
    #beforeLikeId = (dt7948.i(this, "beforeLikeId"), void 0);
    static #_6 = dt7948.g(this.prototype, "beforeReactionUserId", [_tracking.tracked], function () {
      return null;
    });
    #beforeReactionUserId = (dt7948.i(this, "beforeReactionUserId"), void 0);
    #getLastIdFrom(array) {
      return array.length ? array[array.length - 1].get("id") : null;
    }
    #updateBeforeIds(reactionUsers) {
      if (this.includeLikes) {
        const mainReaction = this.siteSettings.discourse_reactions_reaction_for_like;
        const [likes, reactions] = reactionUsers.reduce((memo, elem) => {
          if (elem.reaction.reaction_value === mainReaction) {
            memo[0].push(elem);
          } else {
            memo[1].push(elem);
          }
          return memo;
        }, [[], []]);
        this.beforeLikeId = this.#getLastIdFrom(likes);
        this.beforeReactionUserId = this.#getLastIdFrom(reactions);
      } else {
        this.beforeReactionUserId = this.#getLastIdFrom(reactionUsers);
      }
    }
    loadMore() {
      if (!this.canLoadMore || this.loading) {
        return;
      }
      this.loading = true;
      const reactionUsers = this.model;
      if (!this.beforeReactionUserId) {
        this.#updateBeforeIds(reactionUsers);
      }
      const opts = {
        actingUsername: this.actingUsername,
        includeLikes: this.includeLikes,
        beforeLikeId: this.beforeLikeId,
        beforeReactionUserId: this.beforeReactionUserId
      };
      _discourseReactionsCustomReaction.default.findReactions(this.reactionsUrl, this.username, opts).then(newReactionUsers => {
        reactionUsers.addObjects(newReactionUsers);
        this.#updateBeforeIds(newReactionUsers);
        if (newReactionUsers.length === 0) {
          this.canLoadMore = false;
        }
      }).finally(() => {
        this.loading = false;
      });
    }
    static #_7 = dt7948.n(this.prototype, "loadMore", [_object.action]);
  }
  _exports.default = UserActivityReactions;
});